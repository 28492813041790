import { _axios as Axios } from "services/http-config";
import { NavigateFunction } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { _AuthService } from "services/auth.service";

export const HttpResponseInterceptor = (
  navigate: NavigateFunction,
  setNotification: (type: any, res: any, id?: string) => void,
  enqueueSnackbar: any
) => {
  Axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      switch (response?.config?.method) {
        case "post":
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // setNotification("success", response.data.message);
          break;
        case "put":
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // setNotification("success", response.data.message);
          break;
        case "patch":
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // setNotification("success", response.data.message);
          break;
        case "delete":
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          // setNotification("success", response.data.message);
          break;

        default:
          break;
      }

      return response;
    },
    function (error) {
      // make a copy of the original request to do it again incase we need to refresh the token
      const originalRequest = error?.config;

      switch (error?.response?.status) {
        case 402:
          _AuthService.doLogout();
          navigate("/");
          break;

        case 405:
          Object.keys(error.response.data.message).map((key) =>
            enqueueSnackbar(error.response.data.message[key], {
              variant: "error",
              autoHideDuration: 3000,
            })
          );
          // setNotification("error", error.response.data.message[key]);
          break;

        default:
          enqueueSnackbar(error.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
          break;
      }

      return Promise.reject(error);
    }
  );
};
