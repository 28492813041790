import stylisRTLPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { settingsStore } from "store/settingsStore";
import { useEffect } from "react";

const cacheRtl = createCache({
  key: "mui-rtl",
  prepend: true,
  stylisPlugins: [stylisRTLPlugin],
});

export const RTLProvider = ({ children }: { children: JSX.Element }) => {
  const direction = settingsStore((state) => state.direction);

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  if (direction === "rtl") {
    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
