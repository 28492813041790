import AppRouting from "AppRouting";
import { HttpRequestInterceptor } from "interceptors/http-request.interceptor";
import { HttpResponseInterceptor } from "interceptors/http-response.interceptor";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification } from "store/notificationStore";

function App() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [message, setNotification, clearNotification] = useNotification(
    (state) => [state.message, state.setNotification, state.clearNotification]
  );

  useEffect(() => {
    HttpRequestInterceptor();
    HttpResponseInterceptor(navigate, setNotification, enqueueSnackbar);
  }, []);

  // useEffect(() => {
  //   message?.title &&
  // enqueueSnackbar(message?.title, {
  //   variant: message?.status,
  //   autoHideDuration: 3000,
  // });
  //   setTimeout(() => {
  //     clearNotification();
  //   }, 2900);
  // }, [message?.title]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <AppRouting />;
}

export default App;
