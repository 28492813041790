import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { FC } from "react";
import { settingsStore } from "store/settingsStore";
import { createTheme } from "theme";
import shallow from "zustand/shallow";

interface ThemeProviderWraperProps {
  children: any;
}

export const ThemeProviderWraper: FC<ThemeProviderWraperProps> = ({
  children,
}) => {
  const [direction, mode, responsiveFontSizes] = settingsStore(
    (state) => [state.direction, state.mode, state.responsiveFontSizes],
    shallow
  );
  const theme = createTheme({ direction, mode, responsiveFontSizes });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
