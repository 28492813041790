import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const BoxStyled = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.palette.card.main};
    border-radius: 14px;  
    border: 1px solid ${theme.palette.borderColor.light} ;
    padding: 24px 0 24px 0;
  `
);
