import { ThemeOptions } from "@mui/material";

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const divider = "#E6E8F0";

const text = {
  primary: "#121828",
  secondary: "#65748B",
  disabled: "rgba(55, 65, 81, 0.48)",
};

export const lightThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            backgroundColor: "#F44336",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "#C62828",
            },
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(238, 238, 238,0.2)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            color: "#9e9e9e",
            marginLeft: "0",
          },
          "&.MuiFormHelperText-root.Mui-error": {
            color: "#C62828",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderBottom: `1px solid ${divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",

          ".MuiTableCell-root": {
            borderBottom: `1px solid ${divider}`,
            color: "#212121",
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(237, 231, 246)",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              transition: "250ms",
            },
            color: "#616161",
            backgroundColor: "inherit",
            "&.Mui-selected ": {
              color: "#ffffff",
              backgroundColor: "#2196F3",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiDialog-container .MuiPaper-root": {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 8,
            width: "35%", // this one will be deleted in the end
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    background: { default: "#ffffff", paper: "#E3F2FD" },
    primary: {
      light: "#E3F2FD",
      main: "#2196F3",
      dark: "#1E88E5",
      800: "#1565C0",
      200: "#90CAF9",
    },
    secondary: {
      light: "#EDE7F6",
      main: "#673AB7",
      dark: "#5E35B1",
      800: "#4527A0",
      200: "#B39DDB",
    },
    success: {
      light: "#B9F6CA",
      main: "#69F0AE",
      dark: "#00C853",
      200: "#69F0AE",
    },
    error: {
      light: "#EF9A9A",
      main: "#F44336",
      dark: "#C62828",
    },
    warning: {
      light: "#B9F6CA",
      main: "#FFE57F",
      dark: "#FFC107",
    },
    gray: {
      main: "#666666",
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      500: "#9E9E9E",
      600: "#616161",
      700: "#616161",
      900: "#212121",
      pureWhite: "#ffffff",
    },
    orange: {
      light: "#FBE9E7",
      main: "#FFAB91",
      dark: "#D84315",
    },
    textColor: {
      main: "#212121",
      100: "#616161",
      50: "#9e9e9e",
      active: "#5e35b1",
    },
    card: { main: "#ffffff" },
    borderColor: {
      main: "rgb(189, 189, 189)",
      light: "rgba(144, 202, 249, 0.46)",
    },
    linkColor: {
      main: "rgb(94, 53, 177)",
    },
  },
  typography: {
    // fontFamily: ["ManropeBold", "ManropeRegular"].join(","),

    button: {
      fontSize: "14px",
    },

    h1: {
      fontSize: "64px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "40px",
      fontWeight: "bold",
    },
    h4: {
      fontSize: 26,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 23,
      fontWeight: "bold",
    },
    h6: {
      fontSize: 20,
    },

    subtitle1: {
      fontSize: "0.955rem",
    },
    subtitle2: {
      fontSize: 12,
    },

    body1: {
      fontSize: "0.875rem",
      fontWeight: "500",
    },
    body2: {
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
    },
    inputTitle: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "rgb(97, 97, 97)",
    },
  },

  shadows: [
    "none",
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
    "0px 1px 2px rgba(100, 116, 139, 0.12)",
    "0px 1px 4px rgba(100, 116, 139, 0.12)",
    "0px 1px 5px rgba(100, 116, 139, 0.12)",
    "0px 1px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 6px rgba(100, 116, 139, 0.12)",
    "0px 3px 6px rgba(100, 116, 139, 0.12)",
    "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
    "0px 5px 12px rgba(100, 116, 139, 0.12)",
    "0px 5px 14px rgba(100, 116, 139, 0.12)",
    "0px 5px 15px rgba(100, 116, 139, 0.12)",
    "0px 6px 15px rgba(100, 116, 139, 0.12)",
    "0px 7px 15px rgba(100, 116, 139, 0.12)",
    "0px 8px 15px rgba(100, 116, 139, 0.12)",
    "0px 9px 15px rgba(100, 116, 139, 0.12)",
    "0px 10px 15px rgba(100, 116, 139, 0.12)",
    "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
    "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
    "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
    "0px 25px 50px rgba(100, 116, 139, 0.25)",
  ],
};
