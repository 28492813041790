import create from "zustand";

interface NotificationStoreInterface {
  message?: {
    title?: string | undefined;
    status?: "success" | "error" | "warning" | "info";
    id?: string | number;
  };
  setNotification: (type: any, title: any, id?: string) => void;
  clearNotification: () => void;
}

export const useNotification = create<NotificationStoreInterface>(
  (set: any) => ({
    setNotification: (type: any, title: any, id?: string) =>
      set(() => ({ message: { title: title, status: type } })),
    clearNotification: () =>
      set(() => ({ message: undefined, status: undefined, id: undefined })),
  })
);
