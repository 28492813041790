import { Navigate } from "react-router-dom";
import { _AuthService } from "services/auth.service";

const ShouldNotBeLogged = ({ children }: { children: JSX.Element }) => {
  if (_AuthService.isLoggedIn()) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ShouldNotBeLogged;
