import axios from "axios";
import { _AuthService } from "./auth.service";

const { REACT_APP_API_URL } = process.env;

export const _axios = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: {
    // Authorization: `Bearer ${BASE_URL_TOKEN_KEY}`,
  },
});
