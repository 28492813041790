import { Button, Grid, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { BoxStyled } from "components/styled/BoxStyled";
import { TextFieldStyled } from "components/styled/TextField";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { _AuthService } from "services/auth.service";
import LoginBg from "components/LoginBg";
import LogoDark from "assets/image/logo-dark.png";
import LogoLight from "assets/image/logo-white.svg";
import ButtonLoader from "components/shared/ButtonLoader";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function LoginHandler(input: any) {
    setLoading(true);
    const data = input;
    _AuthService
      .login(data)
      .then(() => {
        location.state
          ? navigate(`${location.state}`)
          : navigate("/dashboard/countries");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <LoginBg>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          // backgroundColor: "background.paper",
          zIndex: "20",
        }}
      >
        <BoxStyled
          sx={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "40px",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box sx={{ width: "120px" }}>
              <img
                src={theme.palette.mode !== "dark" ? LogoDark : LogoLight}
                alt=""
                style={{ width: "100%" }}
              />
            </Box>
            <Typography variant="h5" sx={{ color: "#00568B", mt: "10px" }}>
              Hi, Welcome Back
            </Typography>
            <Typography variant="h6" sx={{ color: "textColor.50", mt: "10px" }}>
              Enter your credentials to continue
            </Typography>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">Name</Typography>
              </Box>
              <TextFieldStyled
                {...register("email")}
                sx={{ width: "100%" }}
                type="email"
                placeholder="Enter your email"
                error={Boolean(errors.email?.message)}
                helperText={(errors.email?.message as any) || ""}
              />
            </Box>
            <Box sx={{ width: "100%", mt: "20px" }}>
              <Box sx={{ margin: "0 0 8px 5px" }}>
                <Typography variant="inputTitle">Password</Typography>
              </Box>
              <TextFieldStyled
                {...register("password")}
                sx={{ width: "100%" }}
                type="password"
                placeholder="Enter your password"
              />
            </Box>
            <Box sx={{ marginTop: "30px", width: "100%" }}>
              <ButtonLoader
                disableOnLoading
                loading={loading}
                onClick={() => handleSubmit(LoginHandler)()}
                fullWidth
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#00568B",

                  "&:hover": { backgroundColor: "secondary.main" },
                }}
              >
                Sign In
              </ButtonLoader>
            </Box>
          </Box>
        </BoxStyled>
      </Box>
    </LoginBg>
  );
};

export default Login;
