import Loader from "components/shared/Loader";
import ShouldBeLogged from "middlewares/ShouldBeLogged";
import ShouldNotBeLogged from "middlewares/ShouldNotBeLogged";
import Login from "pages/Login";
import React from "react";
import { Route, Routes } from "react-router-dom";

const DashboardRouting = React.lazy(
  () => import("modules/Dashboard/DashboardRouting")
);

const AppRouting = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ShouldNotBeLogged>
            <Login />
          </ShouldNotBeLogged>
        }
      />

      <Route
        path="dashboard/*"
        element={
          <ShouldBeLogged>
            <React.Suspense fallback={<Loader />}>
              <DashboardRouting />
            </React.Suspense>
          </ShouldBeLogged>
        }
      />
    </Routes>
  );
};

export default AppRouting;
