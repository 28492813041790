import { _axios as Axios } from "./http-config";

import { AxiosResponse } from "axios";
import { me, RootObj } from "interfaces/common";

const { REACT_APP_Token_key } = process.env;

class AuthService {
  private static _instance: AuthService;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  login(data: any): Promise<AxiosResponse<any, any>> {
    return Axios.post<any>(`/auth/login`, data).then((res) => {
      this.doLogin(res.data.data.token, res.data.data.admin);
      return res;
    });
  }

  logout(): Promise<AxiosResponse<any, any>> {
    return Axios.post<any>(`/admin/logout`).then((res) => {
      this.doLogout();
      return res;
    });
  }

  showMe(): Promise<RootObj<me>> {
    return Axios.get<any>(`/admin/profile`).then((res) => res.data);
  }

  isLoggedIn() {
    return Boolean(this.getJwtToken());
  }

  doLogout() {
    this.destroyTokens();
  }

  doLogin(token: any, user: any) {
    this.storeTokens(token);
  }

  getJwtToken() {
    return localStorage.getItem("token");
  }

  private storeTokens(token: any) {
    localStorage.setItem("token", token);
  }

  private destroyTokens() {
    localStorage.removeItem(REACT_APP_Token_key ?? "token");
  }
}

export const _AuthService = AuthService.Instance;
