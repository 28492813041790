import { Interpolation, Theme } from "@emotion/react";
import { ButtonProps, ThemeOptions } from "@mui/material";

interface PaletteColor {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  500?: string;
  600?: string;
  700?: string;
  900?: string;
  pureWhite?: string;
  active?: string;
}

// those are custome colors that's how you add them
declare module "@mui/material/styles" {
  interface Palette {
    gray: PaletteColor;
    orange: PaletteColor;
    textColor: PaletteColor;
    card: PaletteColor;
    borderColor: PaletteColor;
    linkColor: PaletteColor;
  }
  interface PaletteOptions {
    gray: PaletteColor;
    orange: PaletteColor;
    textColor: PaletteColor;
    card: PaletteColor;
    borderColor: PaletteColor;
    linkColor: PaletteColor;
  }
}

// Typography
declare module "@mui/material/styles" {
  interface TypographyVariants {
    inputTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    inputTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    inputTitle: true;
  }
}

// Button
type MuiButtonVariantsType =
  | {
      props: Partial<ButtonProps<"button", {}>>;
      style: Interpolation<{
        theme: Theme;
      }>;
    }[]
  | undefined;

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    error: true;
    success: true;
  }

  interface ButtonPropsColorOverrides {
    error: true;
    success: true;
  }
}

// Colors

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const divider = "#2D3748";

const background = {
  default: "#1A223F",
  paper: "#111936",
};

const text = {
  primary: "#EDF2F7",
  secondary: "#A0AEC0",
  disabled: "rgba(255, 255, 255, 0.48)",
};

export const darkThemeOptions: ThemeOptions = {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-option": {
            color: "red",
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "error" },
          style: {
            backgroundColor: "#F44336",
            color: "#d7dcec",
            "&:hover": {
              backgroundColor: "#C62828",
            },
          },
        },
        {
          props: { variant: "success" },
          style: {
            backgroundColor: "#F44336",
            color: "#d7dcec",
            "&:hover": {
              backgroundColor: "#C62828",
            },
          },
        },
      ],
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(189, 200, 240,0.2)",
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.MuiFormHelperText-root": {
            color: "#8492c4",
            marginLeft: "0",
          },
          "&.MuiFormHelperText-root.Mui-error": {
            color: "#C62828",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700],
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderBottom: `1px solid ${divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          ".MuiTableCell-root": {
            borderBottom: `1px solid ${divider}`,
            color: "#d7dcec",
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(124, 77, 255, 0.082)",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          "& .MuiPaginationItem-root": {
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
              transition: "250ms",
            },
            color: "#bdc8f0",
            backgroundColor: "inherit",
            "&.Mui-selected ": {
              color: "#ffff",
              backgroundColor: "#2196F3",
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          ".MuiDialog-container .MuiPaper-root": {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 8,
            width: "35%", // this one will be deleted in the end
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: { default: "#111936", paper: "#1A223F" },
    primary: {
      light: "#E3F2FD",
      200: "#90CAF9",
      main: "#2196F3",
      dark: "#1E88E5",
      800: "#1565C0",
    },
    secondary: {
      light: "#EDE7F6",
      200: "#B39DDB",
      main: "#673AB7",
      dark: "#5E35B1",
      800: "#4527A0",
    },
    success: {
      light: "#B9F6CA",
      200: "#69F0AE",
      main: "#69F0AE",
      dark: "#00C853",
    },
    error: {
      light: "#EF9A9A",
      main: "#F44336",
      dark: "#C62828",
    },
    warning: {
      light: "#B9F6CA",
      main: "#FFE57F",
      dark: "#FFC107",
    },
    gray: {
      main: "#666666",
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      500: "#8492C4",
      600: "#BDC8F0",
      700: "#BDC8F0",
      900: "#BDC8F0",
      pureWhite: "#ffffff",
    },
    orange: {
      light: "#FBE9E7",
      main: "#FFAB91",
      dark: "#D84315",
    },
    textColor: {
      main: "#d7dcec",
      100: "#bdc8f0",
      50: "#8492c4",
      active: "#7c4dff",
    },
    card: { main: "#212946" },
    borderColor: {
      main: "rgba(189, 200, 240, 0.157)",
      light: "rgb(17, 25, 54) ",
    },
    linkColor: {
      main: "rgb(124, 77, 255)",
    },
  },
  typography: {
    fontFamily: [
      // "-apple-system",
      // "BlinkMacSystemFont",
      // '"Segoe UI"',
      // "Roboto",
      // '"Helvetica Neue"',
      // "Arial",
      // "sans-serif",
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
    ].join(","),

    button: {
      fontSize: "14px",
    },

    h1: {
      fontSize: "64px",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "40px",
      fontWeight: "bold",
    },
    h4: {
      fontSize: 26,
      fontWeight: "bold",
    },
    h5: {
      fontSize: 23,
      fontWeight: "bold",
    },
    h6: {
      fontSize: 20,
    },

    subtitle1: {
      fontSize: "0.955rem",
    },
    subtitle2: {
      fontSize: 12,
    },

    body1: {
      fontSize: "0.875rem",
      fontWeight: "500",
    },
    body2: {
      fontSize: "14px",
    },
    caption: {
      fontSize: "12px",
    },
    inputTitle: {
      fontSize: "0.875rem",
      fontWeight: 500,
      color: "rgb(189, 200, 240)",
    },
  },
  shadows: [
    "none",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 4px rgba(0, 0, 0, 0.24)",
    "0px 1px 5px rgba(0, 0, 0, 0.24)",
    "0px 1px 6px rgba(0, 0, 0, 0.24)",
    "0px 2px 6px rgba(0, 0, 0, 0.24)",
    "0px 3px 6px rgba(0, 0, 0, 0.24)",
    "0px 4px 6px rgba(0, 0, 0, 0.24)",
    "0px 5px 12px rgba(0, 0, 0, 0.24)",
    "0px 5px 14px rgba(0, 0, 0, 0.24)",
    "0px 5px 15px rgba(0, 0, 0, 0.24)",
    "0px 6px 15px rgba(0, 0, 0, 0.24)",
    "0px 7px 15px rgba(0, 0, 0, 0.24)",
    "0px 8px 15px rgba(0, 0, 0, 0.24)",
    "0px 9px 15px rgba(0, 0, 0, 0.24)",
    "0px 10px 15px rgba(0, 0, 0, 0.24)",
    "0px 12px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 13px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 14px 24px -8px rgba(0, 0, 0, 0.24)",
    "0px 20px 25px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
  ],
};
