import {
  Box,
  Button,
  CircularProgress,
  Typography,
  ButtonProps,
} from "@mui/material";
import React from "react";

const ButtonLoader = (
  props: ButtonProps & { loading: boolean; disableOnLoading?: boolean }
) => {
  const { loading, disableOnLoading, ...rest } = props;

  return (
    <Button {...rest} disabled={disableOnLoading === true && loading}>
      {props.loading && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "white" }} size="25px" />
        </Box>
      )}
      <Typography sx={{ visibility: props.loading ? "hidden" : "visible" }}>
        {props.children}
      </Typography>
    </Button>
  );
};

export default ButtonLoader;
