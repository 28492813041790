import { Direction } from "@mui/material";
import create from "zustand";

export type ModeUnion = "light" | "dark";

interface SettingsState {
  direction: Direction;
  responsiveFontSizes: boolean;
  mode: ModeUnion;
}

interface SettingsActions {
  setDirection: (dir: Direction) => void;
  setResponsiveFontSizes: (resFont: boolean) => void;
  setMode: (mode: ModeUnion) => void;
}

function storeMode(mode: ModeUnion) {
  localStorage.setItem("mode", mode);
}

function getMode() {
  if (!!localStorage.getItem("mode")) {
    return localStorage.getItem("mode") as ModeUnion;
  } else {
    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  }
}

export const settingsStore = create<SettingsState & SettingsActions>((set) => ({
  direction: "ltr",
  responsiveFontSizes: true,
  mode: getMode(),

  setDirection: (dir) => set(() => ({ direction: dir })),
  setResponsiveFontSizes: (resFont) =>
    set(() => ({ responsiveFontSizes: resFont })),
  setMode: (mode) => {
    set(() => ({ mode: mode }));
    storeMode(mode);
  },
}));
